import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import Plaatjie from '@ubo/plaatjie'
import SimpleReactLightbox, { SRLWrapper } from 'simple-react-lightbox'

// Components
import SEO from 'components/shared/SEO'
import Layout from 'components/layout/Layout'
import BannerDetail from 'components/flex/Banner/BannerDetail'
import ParseContent from 'components/shared/ParseContent'

const ImageWrapper = styled.div`
  height: 366px;
  @media (max-width: 767px) {
    height: 180px;
  }
  @media (max-width: 575px) {
    height: 120px;
  }
`

const Image = styled(Plaatjie)`
  width: 100%;
  height: 100%;
  transition: opacity 0.3s ease-in-out;

  &:hover {
    opacity: 0.75;
    cursor: pointer;
  }
`

const Divider = styled.div`
  @media (min-width: 1199px) {
    width: 50%;
  }
`

const Content = styled(ParseContent)`
  & a:hover {
    text-decoration: underline;
  }
`

const Details = styled.div`
  margin-top: -3rem;
  background: ${({ theme }) => theme.color.background};
  position: relative;
  right: 0;
  z-index: 2;
  font-size: ${({ theme }) => theme.font.size.big};
  color: ${({ theme }) => theme.color.light};
  padding-top: -120px;

  & p {
    font-size: ${({ theme }) => theme.font.size.big};
    font-weight: ${({ theme }) => theme.font.weight.light};
  }
`

interface PageTemplateProps {
  data: {
    page: {
      path?: string
      title?: string
      seo: any
      // eslint-disable-next-line camelcase
      fields: GatsbyTypes.WpProject_Projectdetail
      subcategories: Array<Object>
    }
    projects: {
      nodes: GatsbyTypes.WpProject[]
    }
  }
}

const PageTemplate: React.FC<PageTemplateProps> = ({
  data: {
    page: { title, seo, fields, subcategories },
  },
}) => {
  const options = {}

  const subCategory = subcategories.nodes.map(
    ({ name }, index) => `${index > 0 ? ', ' : ''}${name}`
  )

  return (
    <Layout>
      <BannerDetail
        title={title}
        image={fields.mainimage}
        subcategory={subCategory}
      />
      <div className="container mb-5 pb-5">
        <div className="row justify-content-between my-5">
          <div className="col-lg-5 order-lg-2 mb-5">
            <Details className="p-5">
              {fields.lines?.map((line, index) => (
                <div
                  className="d-flex flex-column flex-xl-row justfiy-content-between p-2"
                  // eslint-disable-next-line react/no-array-index-key
                  key={index}
                >
                  <Divider>
                    <span className="font-weight-bold">{line?.title}</span>
                  </Divider>
                  <Divider>
                    <span>
                      <ParseContent content={line?.description} />
                    </span>
                  </Divider>
                </div>
              ))}
            </Details>
          </div>
          <div className="col-lg-5 order-lg-1">
            <Content content={fields.description} />
          </div>
        </div>
        <div className="py-5">
          <SimpleReactLightbox>
            <SRLWrapper options={options}>
              <div className="row">
                {fields.gallery?.map((image, index) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <ImageWrapper className="col-6 mt-3" key={index}>
                    <Image image={image} alt={image?.altText || `${title}`} />
                  </ImageWrapper>
                ))}
              </div>
            </SRLWrapper>
          </SimpleReactLightbox>
        </div>
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query projectById($pageId: String!) {
    page: wpProject(id: { eq: $pageId }) {
      id
      title
      subcategories: projectcategories {
        nodes {
          name
        }
      }
      fields: projectdetail {
        mainimage {
          localFile {
            childImageSharp {
              gatsbyImageData(quality: 100, width: 1400)
            }
          }
        }
        description
        lines {
          title
          description
        }
        gallery {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(quality: 100, width: 1400)
            }
          }
        }
      }
    }
  }
`

export default PageTemplate
