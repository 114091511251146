import React from 'react'
import styled from 'styled-components'

// Elements
import Plaatjie from '@ubo/plaatjie'

const Description = styled.div`
  min-height: 509px;
  background-color: ${({ theme }) => theme.color.primary};
  opacity: 0.75;
  & h1 {
    font-size: ${({ theme }) => theme.font.size.mega};
    line-height: ${({ theme }) => theme.font.size.giga};
    text-shadow: 0px 0px 18px rgba(0, 0, 0, 0.8);
    font-weight: ${({ theme }) => theme.font.weight.light} !important;
    color: ${(props) => props.theme.color.light};
    @media (max-width: 575px) {
      font-size: ${({ theme }) => theme.font.size.large};
      line-height: ${({ theme }) => theme.font.size.large};
    }
  }
  & h6 {
    color: ${({ theme }) => theme.color.background};
    font-size: ${({ theme }) => theme.font.size.big};
  }
  @media (max-width: 991px) {
    max-height: 269px;
    min-height: unset;
  }
`

const Container = styled.div`
  @media (max-width: 1440px) {
    margin-right: 0 !important;
  }
`

const Image = styled(Plaatjie)<{ image: any }>`
  height: 100%;
  width: 100%;

  & img {
    object-fit: cover !important;
  }
  @media (max-width: 991px) {
    max-height: 269px;
  }
`

const BannerDetailBackground = styled.div`
  background-color: ${({ theme }) => theme.color.primary};
  position: absolute;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  z-index: 1;
  overflow: hidden;

  & > .container {
    @media (min-width: 992px) {
      background-color: ${({ theme }) => theme.color.primary};
    }

    @media (min-width: 1400px) {
      max-width: 1400px;
    }

    @media (min-width: 1650px) {
      max-width: 1600px;
    }

    @media (max-width: 1399px) {
      max-width: 100%;
    }
  }
  @media (max-width: 991px) {
    max-height: 269px;
  }
`

const BannerHighlightedContent = styled.div`
  position: relative;
  z-index: 2;
  @media (max-width: 991px) {
    min-width: 100% !important;
    max-width: 100% !important;
    max-height: 269px;
  }
`

const Wrapper = styled.section``

interface BannerDetailProps {
  // eslint-disable-next-line
  image: any
  title: string | undefined
  subcategory: string | undefined
  lines: any
}

const BannerDetail: React.FC<BannerDetailProps> = ({
  image,
  title,
  subcategory,
}) => (
  <Wrapper className="position-relative">
    <BannerDetailBackground>
      <Container className="container h-100">
        <div className="row h-100 justify-content-end">
          <div className="col-lg-6 px-0">
            {/* @ts-ignore */}
            <Image image={image} alt={title} />
          </div>
        </div>
      </Container>
    </BannerDetailBackground>
    <BannerHighlightedContent className="container h-100">
      <div className="row h-100">
        <Description className="col-lg-6 d-flex align-items-start align-items-lg-center py-5 ">
          <div className="py-4">
            {subcategory && (
              <div className="mb-5">
                <h6 className="text-uppercase">{subcategory}</h6>
              </div>
            )}
            <h1 className="text-uppercase pb-3 pe-lg-5">{title}</h1>
          </div>
        </Description>
      </div>
    </BannerHighlightedContent>
  </Wrapper>
)

export default BannerDetail
